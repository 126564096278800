import merge from 'lodash/merge';
import { connect } from 'react-redux';

import { ReduxState } from '../AppLoader/types';
import { SearchPaginationState } from '../app/Search/SearchTypes';
import { fetchCollectionsResults } from '../app/Search/actions/CollectionsActions';
import AbstractSearchResultsContainer, {
  mapStateToProps,
} from '../app/Search/containers/AbstractSearchResultsContainer';
import {
  selectCollectionUrlName,
  selectPagination,
  selectStockItems,
} from '../app/Search/selectors/searchSelectors';
import { StockItem } from '../common/types/StockItemTypes';

interface SingleAssetCollectionContainerProps {
  stockItems: StockItem[];
  pagination: SearchPaginationState;
}

class SingleAssetCollectionContainer extends AbstractSearchResultsContainer {
  getCurrentStockItems(): StockItem[] | null {
    return this.props.stockItems;
  }

  getPagination(): SearchPaginationState {
    return this.props.pagination;
  }

  goToPage = (newPageNo: number): void => {
    this.props.dispatch(
      fetchCollectionsResults(this.props.collectionUrlName, newPageNo, false)
    );

    this.scrollToTop();
  };
}

function mapStateToPropsForCollections(
  state: ReduxState
): SingleAssetCollectionContainerProps {
  const stockItems = selectStockItems(state);

  return merge(mapStateToProps(state), {
    collectionUrlName: selectCollectionUrlName(state),
    pagination: selectPagination(state),
    stockItems: Object.keys(stockItems).map((id) => stockItems[id]),
  });
}

export default connect(mapStateToPropsForCollections)(
  SingleAssetCollectionContainer
);
