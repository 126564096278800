import './commonAssets';

import '../common/javascripts/modules/member/MemberSubMembers.js';
import '../common/javascripts/modules/signup/JoinStep1BMT.js';
import '../common/javascripts/modules/survey/SurveyRedirect.js';

import '../audioblocks/stylesheets/audioblocks-style-custom.less';
import '../audioblocks/stylesheets/modules/details-uff.less';
import '../common/stylesheets/maintenance.css';
import '../common/stylesheets/modules/collections/collection-browse.less';
import '../common/stylesheets/modules/collections/collection-template.less';
import '../common/stylesheets/modules/widgets/testimonials.less';
import '../common/stylesheets/modules/howItWorks/all-media-types-info.less';
import '../common/stylesheets/modules/notfound/not-found.less';
import '../common/stylesheets/modules/popups/paymentMethodInfo.less';
import '../common/stylesheets/modules/signup/bmt-free-trial.less';
import '../common/stylesheets/modules/signup/sign-up.less';
import '../common/stylesheets/modules/signup/user-lead-form-drop-shadow.less';
import '../common/stylesheets/modules/survey/survey.less';
import '../common/stylesheets/modules/widgets/full-width-background-widget.less';
import '../common/stylesheets/modules/widgets/stock-item-group-widget-card-design.less';
import '../common/stylesheets/modules/widgets/stock-item-group-widget.less';
import '../react/builder-io/builder-io.less'


import AppLoader from '../react/AppLoader/AppLoader';
import StockItemCollectionContainer from '../react/app/Search/containers/StockItemCollectionContainer';
import PageNotFound from '../react/common/components/PageNotFound';
import PopUpsContainer from '../react/PopUps/PopUpsContainer';
import GenericFAQ from '../react/common/components/GenericFAQ';
import AudioSearchContainer from '../react/app/Search/containers/AudioSearchContainer';
import AudioSiteConstants from '../react/common/SiteConstants/AudioSiteConstants';
import BuilderClientSideRenderer from '../react/builder-io/BuilderClientSideRenderer';
import EntTrialBanner from "../react/Trials/EntTrialBanner";
import NavApps from "../react/app/NavApps";

const audioSiteConstants = new AudioSiteConstants();

AppLoader.load({
  routes: [
    {
      name: 'Search',
      path: audioSiteConstants.getSearchRoute() + '*',
      component: AudioSearchContainer,
    },
    // NOTE: this is for the non-seo-friendly search routes on AudioBlocks
    {
      name: 'Search',
      path: '/search*',
      component: AudioSearchContainer,
    },
    {
      name: 'Collection',
      path: audioSiteConstants.getCollectionsRoute(),
      component: StockItemCollectionContainer,
    },
    // NOTE: this needs to go last!
    {
      name: 'PageNotFound',
      path: '*',
      component: PageNotFound,
    },
  ],
  auxApps: {
    ...NavApps,
    'pop-up-container': PopUpsContainer,
    'builder-io-wrapper': BuilderClientSideRenderer,
  },
  propBasedApps: {
    genericFrequentlyAskedQuestions: GenericFAQ,
    'entTrialBanner': EntTrialBanner
  },
});
