import React from 'react';

import { ContentCollection } from '../../app/ContentCollection/ContentCollectionState';
import { SearchFeatures } from '../../app/Search/SearchTypes';
import VideoStockItemCard from '../../app/Search/components/card/VideoStockItemCard';
import { StockItemDetails } from '../../common/types/StockItemTypes';

interface VideoGridComponentProps {
  collection: ContentCollection;
  stockItems: Record<number, StockItemDetails>;
  features: SearchFeatures;
  visibleOnMobile?: boolean;
}

const VideoGridComponent = (props: VideoGridComponentProps): JSX.Element => {
  const { collection, stockItems, features, visibleOnMobile } = props;

  const cards = collection.content.video.map((id) => {
    const details = stockItems[id];
    if (!details) {
      return null;
    }
    const { stockItem } = details;
    return (
      <VideoStockItemCard
        key={id}
        stockItem={stockItem}
        redirectToSignUpLink={features.redirectToSignUpLink}
        hideOnThreeColumnLayout={false}
        style={{}}
        classNames={['p-0 my-0']}
        stockItemFormats={details.stockItemFormats}
        shouldShowAddToFavorites={true}
        shouldShowDownloadButton={true}
        shouldShowSelectCheckbox={false}
        isFavorite={details.isFavorite}
        isSavingFavorite={stockItem.isSavingFavorite}
        containerResponsiveClassNames={[]}
      />
    );
  });

  const mobileClass = visibleOnMobile ? 'flex' : 'hidden';

  return (
    <div
      className={`w-full flex-col align-items-start gap-6 md:flex ${mobileClass}`}
      data-cy="cross-asset-video-grid"
    >
      <h2
        className="flex flex-row align-center p-0 m-0 gap-1"
        data-cy="collection-video-header"
      >
        <img
          src={`${__ASSETS_COMMON_IMAGES_URL__}/collections/cross-asset/video.svg`}
          alt="video logo"
          className="w-6 h-6"
        />
        <span className="font-sans font-bold text-2xl text-gray-800 pl-1">
          Video
        </span>
      </h2>
      <div
        className="stock-item-group-wrapper card-design grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 md:overflow-y-auto"
        data-testid="cac-video-panel"
      >
        {cards}
      </div>
    </div>
  );
};
export default VideoGridComponent;
