import React from 'react';

import { ContentCollection } from '../../app/ContentCollection/ContentCollectionState';
import AudioStockItemCard from '../../app/Search/components/card/AudioStockItemCard';
import {
  StockItemContext,
  StockItemDetails,
} from '../../common/types/StockItemTypes';

interface AudioListComponentProps {
  collection: ContentCollection;
  stockItems: StockItemDetails[];
  visibleOnMobile: boolean;
}

const AudioListComponent = (
  props: AudioListComponentProps
): JSX.Element | null => {
  const { collection, stockItems, visibleOnMobile } = props;

  if (!collection.content.audio) {
    return null;
  }

  const mobileClass = visibleOnMobile ? 'flex' : 'hidden';

  return (
    <div
      className={`md:flex flex-col gap-6 min-w-330 md:max-w-25vw ${mobileClass}`}
    >
      <h2
        className="flex flex-row align-center p-0 m-0 gap-1"
        data-cy="collection-audio-header"
      >
        <img
          src={`${__ASSETS_COMMON_IMAGES_URL__}/collections/cross-asset/audio.svg`}
          alt="music logo"
          className="w-6 h-6"
        />
        <span className="font-sans font-bold text-2xl text-gray-800 pl-1">
          Audio
        </span>
      </h2>
      <div className="md:overflow-y-auto stock-item-group-wrapper card-design">
        {collection.content.audio.map((id, key) => {
          const item = stockItems[String(id)];
          if (!item) {
            return null;
          }
          return (
            <div key={key} className="pb-4 px-0.5">
              <AudioStockItemCard
                classNames={'rounded-md'}
                stockItem={item.stockItem}
                stockItemFormats={item.stockItemFormats}
                stockItemArtists={item.stockItemArtists}
                shouldShowDownloadButton={true}
                shouldShowAddToFavorites={true}
                context={StockItemContext.CROSS_ASSET_COLLECTION}
                topTags={item.topTags}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default AudioListComponent;
