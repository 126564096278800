import React, { useEffect, useState } from 'react';

import LoadingIndicator from './LoadingIndicator';

const PageNotFound = () => {
  const [pageHtml, setPageHtml] = useState();

  useEffect(() => {
    //Avoiding a state update once unmounted
    let mounted = true;

    fetch('/404?raw=true')
      .then((response) => response.text())
      .then((text) => mounted && setPageHtml(text));

    return () => {
      mounted = false;
    };
  }, []);

  return pageHtml ? (
    <div dangerouslySetInnerHTML={{ __html: pageHtml }}></div>
  ) : (
    <LoadingIndicator />
  );
};

export default PageNotFound;
