import { builder, Builder, BuilderComponent } from '@builder.io/react';
import React from 'react';

import { ReduxState } from '../AppLoader/types';
import {
  selectIsLoggedIn,
  selectIsMobile,
  selectPrimarySubscription,
} from '../auth/AuthSelectors';
import PageNotFound from '../common/components/PageNotFound';
import './builder-settings';

/**
 * This currently only works on the client because @builder.io/react packages
 * cause "window / document is undefined" errors on the server (i.e. V8Js)
 */
const BuilderClientSideRenderer = () => {
  const state = __INITIAL_STATE__ as ReduxState;
  const builderPageContent =
    JSON.parse(__BUILDER_PAGE_CONTENT__)?.results?.[0] || null;
  const locale = state?.builder?.locale || 'US';
  const isEditingOrPreviewing = Builder.isEditing || Builder.isPreviewing;
  const subscription = selectPrimarySubscription(state);

  builder.setUserAttributes({
    loggedIn: selectIsLoggedIn(state),
    planClassification: subscription?.planClassification,
    isMobile: selectIsMobile(state),
  });

  return builderPageContent || isEditingOrPreviewing ? (
    <BuilderComponent
      locale={locale}
      model="page"
      content={builderPageContent}
      inlineContent={!isEditingOrPreviewing} // allows custom content to be passed manually
    />
  ) : (
    <PageNotFound />
  );
};

/** Render BuilderComponent for editing symbols (i.e. model=symbols) */
const BuilderEditing = () => {
  const model = new URLSearchParams(__CURRENT_SEARCH_PARAMS__).get('model');
  return <BuilderComponent model={model} />;
};

/** Determine whether to render builder page, or allow for editing different models */
const BuilderRouter = () => {
  const builderEditingPathRegex = /\/builder-editing/;

  return __CURRENT_PATH__.match(builderEditingPathRegex) ? (
    <BuilderEditing />
  ) : (
    <BuilderClientSideRenderer />
  );
};

export default BuilderRouter;
