import { ReduxState } from '../../AppLoader/types';
import {
  CollectionStockItemDetails,
  ContentCollection,
  CrossAssetTab,
} from './ContentCollectionState';

export const selectContentCollection = (
  state: ReduxState
): ContentCollection | null => state.app.contentCollection.collection;

export const selectStockItemDetails = (
  state: ReduxState
): CollectionStockItemDetails | null =>
  state.app.contentCollection.stockItemDetails;

export const selectSelectedTab = (state: ReduxState): CrossAssetTab =>
  state.app.contentCollection.selectedTab;

export const selectCollectionDirectoryLink = (state: ReduxState): string =>
  state.app.contentCollection.collectionDirectoryLink;
