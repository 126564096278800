import React from 'react';
import { useSelector } from 'react-redux';

import CrossAssetCollectionContainer from '../../../Collections/CrossAssetCollectionContainer';
import SingleAssetCollectionContainer from '../../../Collections/SingleAssetCollectionContainer';
import { selectContentCollection } from '../../ContentCollection/contentCollectionSelectors';

const StockItemCollectionContainer = (): JSX.Element => {
  const collection = useSelector(selectContentCollection);

  if (collection) {
    return <CrossAssetCollectionContainer />;
  }

  return <SingleAssetCollectionContainer />;
};
export default StockItemCollectionContainer;
