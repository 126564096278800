import { createSelector } from '@reduxjs/toolkit';
import React from 'react';
import { useSelector } from 'react-redux';

import { CrossAssetTab } from '../app/ContentCollection/ContentCollectionState';
import {
  selectCollectionDirectoryLink,
  selectContentCollection,
  selectSelectedTab,
  selectStockItemDetails,
} from '../app/ContentCollection/contentCollectionSelectors';
import { selectSearchFeatures } from '../app/Search/selectors/searchSelectors';
import AudioListComponent from './components/AudioListComponent';
import MobileHeader from './components/MobileHeader';
import VideoGridComponent from './components/VideoGridComponent';

const selector = createSelector(
  [
    selectContentCollection,
    selectStockItemDetails,
    selectSearchFeatures,
    selectSelectedTab,
    selectCollectionDirectoryLink,
  ],
  (
    contentCollection,
    stockItemDetails,
    searchFeatures,
    selectedTab,
    collectionDirectoryLink
  ) => ({
    collection: contentCollection,
    stockItemDetails,
    features: searchFeatures,
    selectedTab,
    collectionDirectoryLink,
  })
);

const CrossAssetCollectionContainer = (): JSX.Element | null => {
  const {
    collection,
    collectionDirectoryLink,
    features,
    stockItemDetails,
    selectedTab,
  } = useSelector(selector);

  if (!collection || !stockItemDetails) {
    return null;
  }

  return (
    <>
      <MobileHeader
        collectionDirectoryLink={collectionDirectoryLink}
        selectedTab={selectedTab}
      />
      <div className="flex flex-row gap-4 md:gap-8 xl:gap-16 clear-both md:max-h-75vh">
        <VideoGridComponent
          collection={collection}
          stockItems={stockItemDetails.video}
          features={features}
          visibleOnMobile={selectedTab === CrossAssetTab.video}
        />
        <AudioListComponent
          collection={collection}
          stockItems={stockItemDetails.audio}
          visibleOnMobile={selectedTab === CrossAssetTab.audio}
        />
      </div>
    </>
  );
};
export default CrossAssetCollectionContainer;
