import React from 'react';
import { useDispatch } from 'react-redux';

import { ChevronLeft } from '@videoblocks/react-icons';

import { changeMobileTab } from '../../app/ContentCollection/ContentCollectionReducer';
import { CrossAssetTab } from '../../app/ContentCollection/ContentCollectionState';

interface BackToCollectionsProps {
  collectionDirectoryLink: string;
}

const BackToCollections = ({
  collectionDirectoryLink,
}: BackToCollectionsProps): JSX.Element => {
  return (
    <div className="text-lg inline">
      <a href={collectionDirectoryLink}>
        <ChevronLeft className="mr-3" />
        Collections
      </a>
    </div>
  );
};

interface TabButtonsProps {
  selectedTab: CrossAssetTab;
}

const Button = ({
  isSelected,
  value,
  text,
}: {
  isSelected: boolean;
  value: CrossAssetTab;
  text: string;
}): JSX.Element => {
  const dispatch = useDispatch();
  const color = isSelected ? 'bg-gray-300 text-gray-900' : '';
  return (
    <button
      className={`rounded-full py-1 px-6 border-none ${color}`}
      onClick={() => dispatch(changeMobileTab(value))}
    >
      {text}
    </button>
  );
};

const ContentTypeButtons = ({ selectedTab }: TabButtonsProps): JSX.Element => {
  return (
    <div className="float-right inline-flex content-center font-sans">
      <Button
        isSelected={selectedTab === CrossAssetTab.video}
        value={CrossAssetTab.video}
        text="Video"
      />
      <Button
        isSelected={selectedTab === CrossAssetTab.audio}
        value={CrossAssetTab.audio}
        text="Audio"
      />
    </div>
  );
};

const MobileHeader = ({
  collectionDirectoryLink,
  selectedTab,
}: TabButtonsProps & BackToCollectionsProps): JSX.Element => {
  return (
    <div className="my-6 block md:hidden">
      <BackToCollections collectionDirectoryLink={collectionDirectoryLink} />
      <ContentTypeButtons selectedTab={selectedTab} />
    </div>
  );
};
export default MobileHeader;
